<template>
	<div class="report">
		<form class="form">
			<div class="form-group">
				<label>Company</label>

				<div class="form-group-input">
					<label>From</label>
					<v-select
						v-model="report.companyFrom"
						:items="companies"
						dense
						outlined
						hide-details="auto"
						height="26px"
						style="width: 260px; max-width: 260px"
					></v-select>
				</div>
				<div class="form-group-input">
					<label>To</label>
					<v-select
						v-model="report.companyTo"
						:items="companies"
						dense
						outlined
						hide-details="auto"
						height="26px"
						style="width: 260px; max-width: 260px"
					></v-select>
				</div>
			</div>

			<div class="form-group">
				<label>Start Month</label>

				<v-select
					v-model="report.startMonth"
					:items="months"
					dense
					outlined
					hide-details="auto"
					height="26px"
					style="max-width: 100px"
				></v-select>
			</div>

			<div class="form-group">
				<label>End Month</label>

				<v-select
					v-model="report.endMonth"
					:items="months"
					dense
					outlined
					hide-details="auto"
					height="26px"
					style="max-width: 100px"
				></v-select>
			</div>

			<div class="form-group">
				<label>Year</label>

				<v-select
					v-model="report.year"
					:items="years"
					dense
					outlined
					hide-details="auto"
					height="26px"
					style="max-width: 100px"
				></v-select>
			</div>

			<div class="form-group">
				<label>&nbsp;</label>
				<button type="button" class="form-btn" @click="generateReport()">Generate Report</button>
			</div>
		</form>
	</div>
</template>

<script>
export default {
	name: "VisitReport",
	data() {
		return {
			report: {
				companyFrom: "",
				companyTo: "",
				startMonth: "",
				endMonth: "",
				year: "",
			},
			companies: ["All Companies"],
			months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
			years: [2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025],
			formError: false,
		};
	},
	methods: {
		async generateReport() {
			let request = {
				companyFrom: this.report.companyFrom,
				companyTo: this.report.companyTo,
				startMonth: this.months.findIndex((month) => month === this.report.startMonth) + 1,
				endMonth: this.months.findIndex((month) => month === this.report.endMonth) + 1,
				year: this.report.year,
			};

			try {
				//send api post request
				window.open(
					`/api/report/visit-export?companyFrom=${request.companyFrom}&companyTo=${request.companyTo}&startMonth=${request.startMonth}&endMonth=${request.endMonth}&year=${request.year}`,
					"_blank"
				);
			} catch (error) {
				console.log(error);
			}
		},
		async getVendors() {
			try {
				const res = await this.$http.get(`/api/report/list-companies`);
				res.data ? this.companies.push(...res.data) : null;
			} catch (error) {
				console.log(error);
			}
		},
	},
	mounted() {
		this.report.companyFrom = this.companies[0];
		this.report.companyTo = this.companies[0];
		this.report.startMonth = this.months[new Date().getMonth()];
		this.report.endMonth = this.months[new Date().getMonth()];
		this.report.year = new Date().getFullYear();
		this.getVendors();
	},
	beforeCreate() {
		if (!this.$store.getters.isAdmin) {
			this.$router.push("/product-search");
		}
	},
};
</script>

<style lang="scss" scoped>
.report {
	position: relative;

	.form-btn {
		width: inherit;
		font-weight: 700;
		margin-top: 0.2rem;
	}
}
</style>
